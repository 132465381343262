<!-- 按钮 -->
<template>
    <div class="maps">
        <div id="container" ref="mapContainer" class="map-container"></div>
    </div>
</template>

<script>
export default {
    props: {
        mapArr:{
            type:Array,
            default:[]
        }
    },
    data() {
        return {
            firstArr: [116.478935, 39.997761], // 中心点/初始坐标
            map: null, // 地图实例  
            trajectoryData: [
                [106.718849, 26.59507],
                [106.715004, 26.598089],
                [106.725273, 26.602322],
                [106.75208, 26.62959],
            ],
        }
    },
    watch:{
        mapArr:{
            handler(data){
                this.map.destroy()
                this.setmapData(data)
            },
            deep:true
        }
    },
    mounted() { 
        setTimeout(() => {// 异步加载（否则报错initMap is not defined）
            this.initMap();
        },500);
    },
    methods: {
        //设置地图数据
        setmapData(e){
            let that = this
            var path = [];
            var mokerData = [];
            for(let obj of e){
                let site = obj.T_site.split(',')
                if(Number(site[0])>1){
                    path.push([Number(site[0]),Number(site[1])])
                    mokerData.push([site[0],site[1],obj.T_name, obj.T_t, obj.T_rh, obj.T_time,obj.T_ist,obj.T_ish])
                }
            }
            path = path.reverse()
            mokerData = mokerData.reverse()
            AMap.convertFrom(path, 'jps', function (status, result) {
                if (result.info === 'ok') {
                    console.log('link',result)
                    that.drawTrajectory(result.locations)//绘制轨迹
                    that.addMarkersToTrajectory(mokerData)// 添加Marker 
                }
            })
            this.initMap()
        },
        async initMap() {
            this.map = new AMap.Map('container', {
                resizeEnable: true, // 窗口大小调整
                terrain: true, // 开启地形图.
                backgroundColor: 'rgba(0,0,0,0)',// 设置背景色为透明
                center: [106.64535522461, 26.654289245605],	//设置地图中心点坐标
                // mapStyle: 'amap://styles/darkblue',		//设置地图的显示样式，更改darkblue为你想要的样式
                rotateEnable: false,
                pitchEnable: false,
                zoom: 17,
                pitch: 65,
                // rotation: 45,
                // viewMode: '3D',//开启3D视图,默认为关闭
                zooms: [2, 20],
            });
        },
        drawTrajectory(path) {
            let that = this
            const polyline = new AMap.Polyline({
                path: path, // 设置轨迹点数组  
                isOutline: false, // 是否绘制轮廓线  
                outlineColor: '#ffeeee', // 轮廓线颜色  
                borderWeight: 2, // 轮廓线宽度  
                strokeColor: '#0091ff', // 线条颜色  
                strokeWeight: 3, // 线条宽度  
                lineJoin: 'round', // 线条连接处样式  
                lineCap: 'round', // 线条端点样式  
                zIndex: 50, // 叠加顺序  
                bubble: true // 是否显示 marker  
            });
            that.map.add(polyline);
        },
        addMarkersToTrajectory(arr) { 
            let that=  this 
            const starticon = new AMap.Icon({// 创建一个 起点Icon
                size: new AMap.Size(25, 34),// 图标尺寸
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',// 图标的取图地址
                imageSize: new AMap.Size(135, 40),// 图标所用图片大小
                imageOffset: new AMap.Pixel(-9, -3)// 图标取图偏移量
            }); 
            // 创建一个 途径icon
            const througIcon = new AMap.Icon({
                size: new AMap.Size(25, 34),
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                imageSize: new AMap.Size(135, 40),
                imageOffset: new AMap.Pixel(-51.2, -3)
            });
            // 创建一个 终点icon
            const endIcon = new AMap.Icon({
                size: new AMap.Size(25, 34),
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
                imageSize: new AMap.Size(135, 40),
                imageOffset: new AMap.Pixel(-95, -3)
            });
            arr.forEach((point, index) => { 
                var marker = new AMap.Marker({  
                    position: point,  
                    map: this.map,  
                    icon: index==0?starticon:index== arr.length-1?endIcon:througIcon, // 替换为你的图标路径  
                    offset: new AMap.Pixel(-13, -30) // 根据你的图标调整偏移量  
                });  
                // 创建信息窗口实例  
                var infoWindow = new AMap.InfoWindow({  
                    isCustom: true,  
                    content: that.createCustomInfoWindowContent(point) 
                }); 
                // 为标记点添加点击事件  
                marker.on('click', function(e) {
                    console.log('点击1',e,point)
                    // 打开信息窗口，并设置其位置与标记点相同 
                    infoWindow.open(that.map, [point[0],point[1]]); // 注意这里的 `this.getPosition()` 是经纬度 
                });
            });  
        },  
        markerClick(e) {
            var infoWindow = new AMap.InfoWindow({
                // offset: new AMap.Pixel(0, -30)
            })
            infoWindow.setContent(e.target.content);
            console.log('位置',e)
            if(e.lnglat!=undefined){
                infoWindow.open(this.map, [e.lnglat.R,e.lnglat.Q]);
            }
        },
        createCustomInfoWindowContent(data) {  
            return `  
                <div class="custom-info-window">  
                <h6>${data[2]}</h6>  
                <p>温度：${data[3]}°C</p>  
                <p>湿度：${data[4]}%</p>  
                <p>时间：${data[5]}</p>  
                </div>  
            `;  
        }  
    },
    beforeDestroy() {
        // 清理地图资源  
        if (window.map) {
            window.map.destroy();
            window.map = null;
        }
    }
}
</script>
<style lang="scss">
.maps {
    width: 100%;
    height: 100%;
}

.map-container {
    width: 100%;
    height: 100%;
}
.custom-info-window{
    border-radius: 10px;
    padding: 10px 20px;
    color:#5fc5ff;
    color:#5fc5ff;
    border: 2px solid #23488f;
    box-shadow: 0 0 20px 10px rgba(35, 72, 143,0.5) inset;
    background: rgba(#23488f,.9);

    font-size: 12px;
}
</style>